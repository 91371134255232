<template>
  <div class="change-username-page">
    <div class="d-flex flex-column justify-content-center text-center change-username-header">
      <img @click="$router.back()" src="../assets/game/left-keyboard-arrow.png" class="go-back-key">
      Change Your Username
    </div>
    <div class="d-flex flex-column justify-content-center text-center p-4 secondary-bg-colour primary-text change-username-explanation">
      Please enter a new username how you would like to be identified on our leaderboards
    </div>
    <div class="text-center pt-5 pb-4 username-input-container">
      <img src="../assets/game/user-icon.png" class="mb-5 user-profile-icon">
      <div class="d-flex flex-row justify-content-center pt-3 current-username-text">
        <div>Current Username:</div>
        <div class="username">{{ this.user.username }}</div>
      </div>
      <input class="col-11 text-center new-username-input-box" type="text" v-model="newUsername" placeholder="Enter New Username">
      <div v-if="validUsername === false" class="mt-3 username-min-max-character-explanation-text">
        Invalid Username
      </div>
      <button @click="changeUsernameButtonClicked(newUsername)" class="mt-4 col-9 change-username-button">
        Change Username
      </button>
      <button @click="$router.back()" class="mt-1 mb-2 col-4 back-button">Back</button>
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations} from "vuex";
import UserDataService from "@/services/userDataService";
import UtilsService from "@/services/utilsService";

export default {
  name: "EditUsername",
  data() {
    return {
      newUsername: '',
      validUsername: undefined,
    }
  },
  computed: {
    ...mapState(['isLoading', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setUser']),
    async changeUsernameButtonClicked(username) {
      await this.authenticateUsername(username);

      if (this.validUsername) {
        const validateUsernameResponse = await UserDataService.validateUsernameAgainstDB(username, this.token);
        await this.validateUsername(validateUsernameResponse, username)
      }
    },
    async authenticateUsername(playerUsername) {
      this.checkUsernameInput(playerUsername)
      await this.profanityChecker(playerUsername);

      const usernameLength = playerUsername.length;
      const validLength = usernameLength >= 6 && usernameLength <= 14;
      const validProfanity = !this.usernameIncludesProfanity.containsProfanity;

      this.validUsername = validLength && validProfanity;
      return this.validUsername
    },
    checkUsernameInput(playerUsername) {
      this.validUsername = playerUsername !== undefined;
    },
    async profanityChecker(playerUsername) {
      const profanityCheckResponse = await UtilsService.checkUsernameProfanity(playerUsername)
      this.usernameIncludesProfanity = profanityCheckResponse.data
    },
    async validateUsername(validateUsernameResponse, username) {
      if (validateUsernameResponse.data.isValid === false) {
        this.validUsername = true
        return
      }

      if (validateUsernameResponse.data.isValid) {
        this.validUsername = false;
        this.newUsername = username;

        await this.updateUserDataAndRedirect();
      }
    },
    async updateUserDataAndRedirect() {
      await UserDataService.updateUserData(this.user.msisdn, this.newUsername);
      const userData = await UserDataService.getUserStatus(this.user.token);
      this.setUser(userData.data);
      this.$router.push('/settings');
    },
  }
}
</script>

<style scoped>
.change-username-page {
  background-color: #e8e8e8;
}

.change-username-header {
  position: relative;
  font-weight: 600;
  font-size: 18px;
  height: 45px;
  color: #FFFFFF;
  background-color: #EF151C;
}

.go-back-key {
  position: absolute;
  height: 20px;
  left: 10px;
  top: 13px;
}

.change-username-explanation {
  height: 70px;
  border-radius: 0 0 20px 20px;
  font-size: 14px;
  font-weight: 500;
  background-color: #2D343B;
  color: #FFFFFF;
}

.username-input-container {
  position: relative;
  margin-top: -12px;
}

.user-profile-icon {
  height: 120px;
}

.username {
  font-weight: 700;
}

.new-username-input-box {
  height: 50px;
  border-radius: 10px;
  border: 1px solid white;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  z-index: 2;
}

::placeholder {
  font-size: 14px;
  color: #cccccc;
}

.username-min-max-character-explanation-text {
  font-weight: 700;
  font-size: 14px;
}

.change-username-button {
  height: 45px;
  border: 0;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: #FFFFFF;
  background-color: #EF151C;
}

.back-button {
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  border: 0;
  background-color: #2D343B;
  color: #FFFFFF;
}
</style>
