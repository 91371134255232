import {environmentConfig} from "@/config/enviromentConfig";
import axios from "axios";

export default class UtilsService {
    static async checkUsernameProfanity(username) {
        return await axios.post(`${environmentConfig.utilsService}/check-profanity`, {
                textString: username,
            },
        )
    }

    static async doiOne(msisdn) {
        return await axios.get(`${environmentConfig.vodacomIntegrationService}/doi-one`, {
                params: {
                    msisdn: msisdn,
                    revenuePartnerId: 1,
                    action: 'Subscription',
                    channel: 'FreePlayPredictor-Web'
                }
            },
        )
    }

    static async doiTwo(msisdn) {
        return await axios.get(`${environmentConfig.vodacomIntegrationService}/doi-one`, {
                params: {
                    msisdn: msisdn,
                    revenuePartnerId: 1,
                    action: 'Subscription',
                    channel: 'FreePlayPredictor-Web'
                }
            },
        )
    }

}
